@font-face {
  font-family: 'Brandon Grotesque';
  src: url('https://rplstatic.blob.core.windows.net/fonts/BrandonGrotesqueRegular.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Grotesque Medium';
  src: url('https://rplstatic.blob.core.windows.net/fonts/BrandonGrotesqueMedium.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Grotesque Bold';
  src: url('https://rplstatic.blob.core.windows.net/fonts/BrandonGrotesqueBold.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Grotesque Black';
  src: url('https://rplstatic.blob.core.windows.net/fonts/BrandonGrotesqueBlack.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Printed';
  src: url('https://rplstatic.blob.core.windows.net/fonts/BrandonPrintedTwo.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  -webkit-box-sizing:  border-box;
  -moz-box-sizing:  border-box;
}

body, html {
  font-family: 'Brandon Grotesque';
  min-height: 100vh;
  font-size: 16px;
  margin:  0;
  padding:  0;
  background-color: #fff;      
}


h2 {
  font-weight: normal;
  font-family: 'Brandon Grotesque Bold';
  font-size: 1.8em;
  margin:  0px 0 10px 0;
  line-height:  1.1;
}

p.p-margin {
  margin: 20px 0 0 0;
}

p.p-bold {
  font-weight: normal;
  font-family: 'Brandon Grotesque Bold';
}

.page-title {
  padding: 20px 0;
}

.page-title h2 {
  display: inline-block;
  margin-right: 50px;
}

.page-title .h-bold {
  font-weight: normal;
  font-family: 'Brandon Grotesque Bold';
}

.page-title .h-action {
  margin-left: 5px;
  cursor: pointer;
  color:rgba(0, 0, 0, 1);
  white-space: nowrap;
}

.page-title .h-action::after {
  margin-left: 5px; 
  content: ">";
  color: #F16063;
}

img.fit-image {
  width: 100%;
  height: auto;
}



.ant-layout {
  background: transparent;
}

.page-content {
  font-size: 19px;
}

.ant-layout-header {
  padding: 0;
  background-color: #000;
}

.layout {
  min-height: 100vh;
  display: flex;
  align-items: stretch;
}

.recaptcha {
  margin-top: 25px;
}


.ant-layout-header #logo {
  height: 65px;
  width: 100%;
  max-width: 1000px;
  background-image: url('./assets/images/ripples-logo-red-white-wide.png');
  background-repeat: no-repeat;
  background-position: left;
  margin: 0 auto;
}

.ant-layout-content {
  padding: 30px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}


.ant-form-item {
    margin: 20px 0;
}

.ant-form-item.password-field {
  margin: 20px 0 0 0;
}

.ant-form-item.password-field .ant-form-item-explain {
  display: none;
}

.ant-col-24.ant-form-item-label {
  padding: 0;
}

.ant-form-item label {
  font-weight: normal;
  font-family: 'Brandon Grotesque Bold';
  margin-bottom: 0px;
  text-transform: uppercase;
}

.find-serial-modal .ant-modal-content {
  border-radius: 25px;
}

.find-serial-modal .wrapper {
  padding: 30px;
}

.find-serial-modal .wrapper h2 {
  padding-bottom: 30px;
} 

#signup-row-5 {
  padding-top: 40px;
}

.ant-form-item .ant-input {
  display:  block;
  width:  100%;
  height:  34px;
  border: none;
  border-bottom: 1px solid #979797;
  border-radius:  0px;
  box-shadow: none;
  transition: none;
  padding:  6px 1px;
  font-size:  18px;
  line-height: 1.42857143;
  color: rgba(0, 0, 0, 0.7);
  font: inherit;
}

.ant-form-item .ant-input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.ant-form-item .ant-input-password {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  border-radius:  0px;
  padding: 0px 1px;
}

.ant-form-item .ant-input-password:hover {
  border-color: rgba(0, 0, 0, 0.4);
}

.image-col {
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 400px;
}

.image-col.signup {
  background-image: url('./assets/images/image-col-signup.png');
}

.image-col.reset-password {
  background-image: url('./assets/images/image-col-reset-password.png');
}

.buttons {
  text-align: center; 
}
.ant-btn {
  font-family: 'Brandon Grotesque';
  font-size: 1.2em;
  border: none;
  border-radius: 25px;
  padding: 8px 28px;
  height: auto;
  color: #F16063;
  background: #fff;
  border: 2px #F16063 solid;
  display: block;
  width: 100%;
}

.ant-modal-footer .ant-btn {
  display: inline-block;
  width: auto;
}

.ant-btn:hover {
  border: 2px #F16063 solid;
  color: #F16063;
  background: #f9f9f9;
}

.ant-btn.ant-btn-primary {
  border: 2px #F16063 solid !important;
  background: #F16063 !important;
  color: #fff;
}

.ant-btn.ant-btn-primary:hover {
  color: #fff;
}

a.ant-btn {
  padding-top: 10px !important;
}

.ant-btn:focus {
  background: #f9f9f9; 
}

.ant-btn.ant-btn-primary:focus {
  background: #F16063; 
}


.ant-checkbox-wrapper .ant-checkbox-inner {
  border-color: #000;
  border-radius: 5px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #000;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.ant-checkbox-checked::after {
  border: none;
}


#error-message, #success-message {
  display: none;
  white-space: break-spaces;
}

.links {
  text-align: center;  
  margin: 15px 0 0 0;
}
.links a {
  font-size: 0.9em;
  display: block;
  margin-bottom: 10px;
  color: #979797;
  text-decoration: none;
}
.links a:hover {
  text-decoration: underline;
}


.alert {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-success {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}


.ant-modal-title {
  font-size: 20px;
  font-family: 'Brandon Grotesque Bold';
  font-weight: normal;
}

.ant-modal-body {
  font-size: 16px;
}



@media (max-width: 480px) {

  h2 {
    color: #000;
  }

  .ant-layout-content {
    width: 90%;
    margin-left: 5% !important;
  }

  .ant-row, .ant-col {
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .ant-modal-body {
    color: #000;
  }

  .ant-modal-body h2 {
    color: #000;
  }

  .ant-modal-body .ant-col {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .ant-modal-body .ant-col.ant-col-17 {
    margin-top: 50px;
  } 
  
  .ant-modal-body .ant-col.ant-col-7 .fit-image {
    max-height: 300px;
    width: auto;
  }

  .page-title h2 {
    display: block;
    margin-right: 0px;
  }

  .page-title {
    font-size: 20px;
  }


  .ant-layout {
      position: initial;
      height: auto;
  }

  .ant-layout-content .ant-col {
    z-index: 30;
  }

  .image-col {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 315px;
    max-width: 500px !important;
    min-height: 0 !important;
    z-index: 1 !important;
  }


  .ant-col-14 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  form .form-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  

  .ant-layout-content {
    position: initial;
    background: none;
    border: none;
    box-shadow: none;
    top: 0;
    transform: none;
  }
  
  
  .ant-form-item {
    margin: 15px auto 15px auto;
  }
  
  .ant-form-item label {
    display: none; 
  }
  
    
  .ant-form-item .ant-input {
    background: none;
    border-radius: 0;
    color: #878787;
    font-size: 1.3em;
    padding: 0;
    background-color: transparent;
  }
  

  .ant-form-item .ant-input::placeholder {
    color: #878787;
  }


  .ant-form-item .ant-input-password, .ant-form-item .ant-input-password:hover {
    background: transparent;
    padding: 0;
  }

  .ant-form-item .ant-input-password .ant-input {
    border: none;
  }


  .btn {
    display: block;
    margin: 10px auto;
    width: 60%;
  }

  .ant-btn {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0 !important;
  }
  
  
  .ant-layout-header {
    z-index: 20;
    height: 50px;
    padding-top: 1px;
    margin-bottom: 180px;
  }

  .ant-layout-header #logo {
    margin: 21px auto 0 auto;
    height: 125px;
    width: 212px;
    background-image: url('./assets/images/logo-mobile.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-color: transparent;
  }



  .find-serial-modal {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    top: 0px;
    padding: 0px;
  }

  .find-serial-modal .ant-modal-content {
    border-radius: 0;
    box-shadow: none;
  }

}

@media (max-width: 374px) {
  .ant-btn {
    font-size: 1em;
  }

  .ant-modal-title {
    font-size: 18px;
  }
}