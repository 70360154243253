.password-helper {
    color: #bcbcbc;
    font-size: 12px;
    vertical-align: baseline;
}

.password-helper .check {
    display: inline-block;
    height: 10px;
    width: 15px;
    background-image: url('../../assets/images/icon-x.png');
    background-position: center;
    text-align: center;
    vertical-align: baseline;
}

.password-helper .check.ok {
    background-image: url('../../assets/images/icon-v.png');
}