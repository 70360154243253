.react-phone-input {
    font-family: 'Brandon Grotesque', sans-serif;
    display: flex;
    width: 100%;
}

.react-phone-input .form-control {
    height: 34px;
    border: 0;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 0;
    font-size: 14px;
    width: 100%;
    padding: 0;
    margin-left: 60px;
    border-bottom: 1px solid #979797;
}

.hasError .form-control,
.hasError .selected-flag {
    border-bottom: 1px solid #ff4d4f !important;
}

.react-phone-input .flag-dropdown {
    border: 0;
    width: 50px;
    background: transparent;
}

.react-phone-input .selected-flag {
    width: auto;
    border-radius: 0;
    border-bottom: 1px solid #979797;
}

.react-phone-input .selected-flag .flag img {
    position: absolute;
    left: 20px;
    bottom: -2px;
}

.react-phone-input .flag-dropdown.open .selected-flag .flag img {
    transform: rotate(180deg);
}

.react-phone-input .country-list {
    width: 250px;
}

.react-phone-input .country-list .search-box {
    width: 90%;
}

@media (max-width: 480px) {
    .react-phone-input .form-control {
        font-size: 18px;
        color: #878787;
    }

    .react-phone-input .form-control::placeholder {
        color: #878787;
    }
}